<template>
  <div class="app-container">
    <div class="head-container">
      <el-date-picker size="small" class="filter-item" v-model="query.dateRange" type="daterange" value-format="timestamp" range-separator="至" start-placeholder="调拨日期" end-placeholder :default-time="defaultTime" @change="toQuery" />
      <quick-select class="filter-item" v-model="query.outWarehouseId" clearable displayField="name" url="api/warehouse" filterable placeholder="发货库房" style="width:180px" @change="toQuery" />
      <quick-select class="filter-item" v-model="query.inWarehouseId" clearable displayField="name" url="api/warehouse" filterable placeholder="收货库房" style="width:180px" @change="toQuery" />
      <el-select class="filter-item" v-model="query.status" clearable filterable style="width: 120px" @change="toQuery">
        <template v-for="(s, i) in status">
          <el-option :key="i" :value="i" :label="s.label" v-if="s.enable" />
        </template>
      </el-select>
      <el-select v-model="query.isMiniProgram" filterable clearable placeholder="操作来源" class="filter-item" style="width: 120px;" @change="toQuery">
        <el-option label="小程序" :value="true" />
        <el-option label="电脑端" :value="false" />
      </el-select>      
      <el-input v-model.trim="query.code" clearable placeholder="输入调拨单号进行搜索" class="filter-item" style="width: 180px" @keyup.enter.native="toQuery" />
      <el-input v-model.trim="query.info" clearable placeholder="输入说明搜索" class="filter-item" style="width: 140px" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <div style="display: inline-block;margin: 0px 2px;">
        <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
      </div>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data" size="small" style="width: 100%;">
      <el-table-column prop="formCode" label="调拨单号" min-width="120" />
      <el-table-column prop="outWarehouseName" label="发货库房" min-width="150" />
      <el-table-column prop="inWarehouseName" label="收货库房" min-width="150" />
      <el-table-column label="调拨日期" width="150" :formatter="r => {return new Date(r.createAt).format('yyyy/MM/dd');}" />
      <el-table-column prop="info" label="说明" min-width="240" show-overflow-tooltip />
      <el-table-column label="状态" width="110" align="center">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="审批日期" width="150" :formatter="r => {return r.checkTime ? new Date(r.checkTime).format('yyyy/MM/dd') : '';}" />
      <el-table-column prop="checkInfo" label="审批意见" min-width="240" show-overflow-tooltip />
      <el-table-column label="操作来源" prop="isMiniProgram" width="100" sortable="custom">
        <template slot-scope="scope">
          {{scope.row.isMiniProgram?"小程序":"电脑端"}}
        </template>
      </el-table-column>      
      <el-table-column width="150px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">{{(scope.row.status === 0 || scope.row.status === 2) ? "编辑" : "查看"}}</el-button>
          <el-button size="mini" type="text" @click="print(scope.row)">打印</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180" v-if="scope.row.status === 0">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>

    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <eForm ref="form" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import initDict from "@/mixins/initDict";
import { del } from "@/api/allocation";
import eForm from "./form";

export default {
  components: { eForm },
  mixins: [initData, initDict],
  data() {
    return {
      delLoading: false,
      defaultTime: ["00:00:00", "23:59:59"],
      status: [
        { label: "编辑中", type: "info", enable: true },
        { label: "已通过", type: "primary", enable: true },
        { label: "已拒绝", type: "danger", enable: true },
        { label: "已发货", type: "warning", enable: false },
        { label: "已完成", type: "success", enable: true },
      ],
      query: {
        dateRange: null,
        outWarehouseId: null,
        inWarehouseId: null,
        code: null,
        status: null
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/allocationForm";
      this.params = {
        page: this.page,
        size: this.size,
        outWarehouseId: this.query.outWarehouseId,
        inWarehouseId: this.query.inWarehouseId,
        formCode: this.query.code,
        status: this.query.status,
        info:this.query.info,
        sort: "formCode,desc",
      };
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        this.params["beginTime"] = query.dateRange[0];
        this.params["endTime"] = query.dateRange[1];
      }
      this.params["isMiniProgram"] = query.isMiniProgram;
      return true;
    },

    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
        });
    },
    add() {
      this.$refs.form && this.$refs.form.resetForm();
    },
    edit(data) {
      this.$refs.form && this.$refs.form.resetForm(data);
    },
    print(data) {
      this.$print("allocation", data.id);
    }
  },
};
</script>